/* CSS para pantallas grandes (tamaño original) */
.contact-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 3%;
}

.image-info-container {
  display: flex;
  align-items: center;
  margin-bottom: 3%;
}

/* .image-info-container {
  display: flex;
  align-items: center; 
  margin-bottom: 3%;
} */

.logo-container {
  flex: 1;
  margin-right: 4%;
}

.contact-info {
  flex: 1;
  text-align: left;
  margin-bottom: 1%;
}

.floating-image {
  width: 500px;
  height: 500px;
  margin: "0 auto";
}

/* CSS para pantallas pequeñas (vista móvil) */
@media screen and (max-width: 768px) {
  .image-info-container {
    flex-direction: column; /* Cambia la dirección de los elementos a columna */
    text-align: center; /* Centra el contenido en la vista móvil */
  }

  .logo-container {
    margin-right: 0; /* Elimina el margen derecho en la vista móvil */
  }

  .contact-info {
    flex: 1; /* Ocupa todo el ancho en la vista móvil */
    margin-top: 10px; /* Espacio entre las columnas */
    text-align: left; /* Justifica el texto en la vista móvil */
    display: flex;
    flex-wrap: wrap;
    margin-left: 5%;
  }

  /* Estilos para los elementos individuales en vista móvil */
  .contact-info > * {
    flex: 1;
    margin: 5px; /* Espacio entre los elementos dentro de contact-info */
  }

  /* Estilos para dividir en dos columnas solo en vista móvil */
  .contact-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .floating-image {
    width: 450px;
    height: 450px;
    margin-bottom: 3%;
  }
}
