.carousel-container {
  margin-top: 10px;
}

.carousel-container img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.control-arrow {
  background: black;
  color: white;
}

.card-with-margin {
  margin: 0 10px; /* Agrega el margen de 10px a cada lado */
}

.info-card {
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 2%;
}

.info-container {
  margin-bottom: 1%;
}

.info-text {
  text-align: justify;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .carousel-container {
    margin-top: 10px;
  }

  .carousel-container img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  .info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .css-1wk95gx-MuiPaper-root-MuiCard-root{
    margin-top: 0% !important;
  }


  .info-card {
    width: 100%;
    margin-top: 10px;
    box-shadow: 0 6px 10px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 3%;
  }

  .info-card .MuiCardActionArea-root {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .info-card .MuiCardActionArea-root .MuiCardMedia-root {
    width: 80%;
    object-fit: cover;
  }
}
