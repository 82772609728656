.footer {
  background-color: #571b3c;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 2%;
  width: 100%;
  transition: position 0.3s, width 0.3s;
}

/* Agrega una clase hide-footer cuando no hay scroll */
.footer.hide-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
}

/* Agrega una clase show-footer cuando hay scroll */
.footer.show-footer {
  position: relative;
  width: auto;
}

.footer-icons {
  display: flex;
  gap: 10px;
}

.footer-icon {
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
  color: #fff;
}

.footer-text {
  text-align: center;
  margin: 0;
  flex-grow: 1;
}