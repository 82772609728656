/* CSS para pantallas grandes (tamaño original) */
.catalogue-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 3%;
}

.lazyLoad {
  height: 100%;
}

.contenedor-catalogo-kula-informacion {
  display: flex;
  align-items: center; /* Alinea verticalmente los elementos */
  margin-bottom: 3%;
}

.contenedor-kula {
  flex: 1;
  margin-right: 4%; /* Ajusta el margen derecho para separar los contenidos */
  width: 450px;
  height: 400px;
}

.kula {
  flex: 1;
  margin-right: 4%; /* Ajusta el margen derecho para separar los contenidos */
  width: 450px;
  height: 400px;
}

.contact-info {
  flex: 1;
  text-align: left;
  margin-bottom: 1%;
}

.contenedor-mas-informacion-catalogo {
  width: 300px;
  margin-top: 1%; /* Ajusta el margen superior según sea necesario */
  display: block; /* Cambia a bloque para que aparezca debajo de contact-info */
  margin-bottom: 10px; /* Espacio opcional entre contact-info y contenedor-mas-informacion */
  margin-right: auto; /* Asegura que esté centrado en la vista móvil */
  margin-left: auto; /* Asegura que esté centrado en la vista móvil */
}

.encabezado-con-linea {
  border-bottom: 1px solid #571b3c; /* Establece un borde inferior estilo línea divisoria */
  padding-bottom: 1%; /* Espacio opcional debajo del elemento */
}

.mas-informacion-boton {
  display: inline-block; /* Cambiado a display inline-block */
  width: 100%;
  height: 100%;
  padding: 8px 0; /* Ajusta el padding según sea necesario */
  border: 1px solid #571b3c; /* Borde personalizado */
  border-radius: 5px; /* Esquinas redondeadas */
  background-color: #571b3c; /* Cambia el fondo al pasar el ratón */
  color: white; /* Cambia el color del texto al pasar el ratón */
  text-decoration: none; /* Quita la subrayado del enlace */
  text-align: center; /* Centrar texto */
  margin-top: 1%;
}

.mas-informacion-boton:hover {
  color: #571b3c; /* Color del texto */
  background-color: transparent; /* Fondo transparente */
  
}

/* CSS para pantallas pequeñas (vista móvil) */
@media screen and (max-width: 768px) {
  .contenedor-catalogo-kula-informacion {
    flex-direction: column; /* Cambia la dirección de los elementos a columna */
    text-align: center; /* Centra el contenido en la vista móvil */
  }

  .contenedor-kula {
    width: 95%;
    height: 100%;
    margin-right: 0%;
  }

  .kula {
    width: 100%;
    height: 300px;
  }

  .contact-info {
    flex: 1; /* Ocupa todo el ancho en la vista móvil */
    margin-top: 10px; /* Espacio entre las columnas */
    text-align: left; /* Justifica el texto en la vista móvil */
    display: flex;
    flex-wrap: wrap;
  }

  /* Estilos para los elementos individuales en vista móvil */
  .contact-info > * {
    flex: 1;
    margin: 5px; /* Espacio entre los elementos dentro de contact-info */
  }

  /* Estilos para dividir en dos columnas solo en vista móvil */
  .contact-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .contenedor-mas-informacion-catalogo{
    width: 100%;
  }

  .mas-informacion-boton {
    display: inline-block; /* Cambiado a display inline-block */
    width: 100%;
    height: 50% !important;
    padding: 8px 0; /* Ajusta el padding según sea necesario */
    border: 1px solid #571b3c; /* Borde personalizado */
    border-radius: 5px; /* Esquinas redondeadas */
    background-color: #571b3c; /* Cambia el fondo al pasar el ratón */
    color: white; /* Cambia el color del texto al pasar el ratón */
    text-decoration: none; /* Quita la subrayado del enlace */
    text-align: center; /* Centrar texto */
    margin-top: 1%;
  }

  .mas-informacion-boton:hover {
    color: #571b3c; /* Color del texto */
    background-color: transparent; /* Fondo transparente */
   
  }
}
