.textos-titulo1 {
  text-align: center;
  font-family: "Source Sans Pro", "Helvetica", sans-serif;
  color: #571b3c;
}

.textos-titulo2 {
  text-align: center;
  font-family: "Source Sans Pro", "Helvetica", sans-serif;
  color: #571b3c;
}

.contenedor-kula-imagenes {
  display: flex;
  margin-top: 3%;
  margin-bottom: 3%;
}

.contenedor-kula-inmueble {
  width: 900px;
  height: 370px;
  margin-left: 2%;
  padding-right: 3%;
}

.kula-iframe {
  width: 100% !important;
  height: 100% !important;
  box-shadow: 0 0 70px #571b3c;
  border-radius: 20px;
  border: 4px solid #571b3c;
}

.contenedor-imagenes {
  width: 550px;
  height: 350px;
  position: relative;
  margin-top: 0.7%;
  margin-right: 2%;
}

.carousel .thumbs-wrapper {
  display: none;
}

.imagen-por-defecto {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%; /* Ajusta el tamaño máximo de la imagen */
  max-height: 100%; /* Ajusta la altura máxima de la imagen */
}

.imagen-subida {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  max-width: 100%; /* Ajusta el tamaño máximo de la imagen */
  max-height: 100%; /* Ajusta la altura máxima de la imagen */
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-container {
  width: 550px;
  height: 350px;
}



.contenedor-datos-inmueble {
  display: flex;
  background-color: #733f59;
  margin-top: 2%;
  text-align: center;
}

.contenedor-datos {
  color: white;
  font-family: "Source Sans Pro", "Helvetica", sans-serif;
  flex: 1;
  padding: 20px; /* Ajusta el espaciado según sea necesario */
  margin-left: 4%;
  margin-right: 4%;
}

.tipo-letra {
  font-size: 20px !important;
}

.contenedor-contacto-google {
  display: flex;
  margin-top: 2%;
  margin-bottom: 3%;
}

.contenedor-contactos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 4%;
  text-align: center;
  margin-left: 4%;
}

.contacto-whatsapp {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Ajusta el espaciado según sea necesario */
}

.contenedor-google-inmueble {
  width: 1150px;
  height: 350px;
  border: 0;
  margin-right: 4%;
  margin-top: 1%;
}

.google-maps-iframe-inmueble {
  width: 100%;
  height: 100%;
}

/* CSS para pantallas pequeñas (vista móvil) */
@media screen and (max-width: 768px) {
  .textos-titulo1 {
    text-align: center;
    font-family: "Source Sans Pro", "Helvetica", sans-serif;
    color: #571b3c;
  }

  .textos-titulo2 {
    text-align: center;
    font-family: "Source Sans Pro", "Helvetica", sans-serif;
    color: #571b3c;
  }

  .contenedor-kula-imagenes {
    display: block;
    margin-left: 4%;
    margin-right: 5%;
  }

  .contenedor-kula-inmueble {
    width: 100% !important;
    height: 350px !important;
    border: 0;
    margin-left: 0% !important;
    padding-right: 2% !important;
    margin-bottom: 8% !important;
  }

  .contenedor-imagenes {
    width: 100% !important;
    height: 350px !important;
    position: relative;
  }

  .carousel .thumbs-wrapper {
    display: none;
  }

  .imagen-por-defecto {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    max-width: 100%; /* Ajusta el tamaño máximo de la imagen */
    max-height: 100%; /* Ajusta la altura máxima de la imagen */
  }

  .imagen-subida {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    max-width: 100%; /* Ajusta el tamaño máximo de la imagen */
    max-height: 100%; /* Ajusta la altura máxima de la imagen */
  }

  .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-container {
    width: 550px;
    height: 350px;
  }

  .kula-iframe {
    width: 100% !important;
    height: 100% !important;
    box-shadow: 0 0 70px #571b3c;
    border-radius: 20px;
    border: 4px solid #571b3c;
  }

  .contenedor-datos-inmueble {
    display: block;
    background-color: #733f59;
    margin-top: 6%;
    text-align: start;
    margin-bottom: 5%;
  }

  .contenedor-datos {
    color: white;
    font-family: "Source Sans Pro", "Helvetica", sans-serif;
    flex: 1;
    padding: 0px; /* Ajusta el espaciado según sea necesario */
    margin-left: 25%;
  }

  .tipo-letra {
    font-size: 15px !important;
  }

  .contenedor-contacto-google {
    display: flex;
    flex-direction: column-reverse; /* Cambiamos el orden en móvil */
    margin-top: 2%;
    margin-bottom: 3%;
    margin-left: 2%;
    margin-right: 2%;
  }

  .contenedor-contactos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 0%;
    text-align: center;
    margin-left: 0%;
  }

  .contacto-whatsapp {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .contenedor-google-inmueble {
    width: 100%;
    height: 350px;
    border: 0;
  }

  .google-maps-iframe-inmueble {
    width: 100%;
    height: 100%;
  }
}
