/* tableStyles.css */
.table-container {
    border: 1px solid black;
    min-width: 100px;
    overflow-x: auto;
  }
  
  
  .table-cell {
    border: 1px solid black;
    border-right: none;
    min-width: 100px;
    
  }
  .table-cell-bold {
    font-weight: bold;
    /* Puedes ajustar otros estilos adicionales aquí */
  }
  
  /* Añade estilos adicionales para las celdas si lo deseas */
  