/* Estilos generales y para vista de escritorio */
.carousel-container {
    margin-top: 10px;
  }
  
  .carousel-container img {
    width: 100%;
    height: 450px;
    object-fit: cover;
  }

  .control-arrow {
    background: black;
    color: white;
  }
  
  .info-card {
    margin-left: 100px;
    margin-right: 100px;
  }
 
  .custom-card-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .justificado {
    text-align: justify;
  }
  
  .info-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  
  .info-item {
    width: 50%; 
    height: 100%; /* Establece una altura del 100% para que tengan el mismo alto */
  }
  
  
  .mision-vision-content {
    display: flex; 
    gap: 20px; 
    align-items: flex-start; 
    justify-content: space-between; 
  }

/* Estilos para vista móvil */
@media (max-width: 768px) {
    .carousel-container {
      margin-top: 10px;
    }
  
    .carousel-container img {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }
  
    .imgen-card {
      width: 80%;
      object-fit: cover;
    }
  
    .info-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .info-card {
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
      margin-top: 10px;
      box-shadow: 0 6px 10px 4px rgba(0, 0, 0, 0.2);
    }
  
    .custom-card-action {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 2%;
    }
  
    /* Estilos para Misión y Visión en vista móvil */
    .mision-vision-content {
      display: flex; /* Cambia a una sola columna */
      flex-direction: column; /* Cambia la dirección a columna */
      align-items: center; /* Centra los elementos verticalmente */
    }
  
    .info-item {
      width: 100%;
      margin-bottom: 1%;
    }
  

  }