.custom-container.div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .custom-box.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background-color: #ffffff;
  }
  
  .custom-textfield.textfield {
    margin-bottom: 16px;
    width: 100%;
  }
  
  .custom-button.button {
    margin-top: 16px;
    width: 100%;
    background-color: #571b3c;
    color: white;
  }
  
  .custom-button.button:hover {
    background-color: white;
    color: #571b3c;
  }
  
  .custom-heading.heading {
    font-size: 24px;
    margin-bottom: 24px;
    font-weight: bold;
    text-align: center;
  }
  
  .custom-subheading.subheading {
    font-size: 14px;
    margin-bottom: 24px;
    text-align: center;
  }
  