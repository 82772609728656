.componente-filtros {
  display: flex;
  margin-bottom: 1%;
  margin-top: 2%;
  margin-left: 10%;
}

.filtros-row {
  display: flex; /* Muestra los filtros en una fila */
}

.filtros-row2 {
  display: flex;
  justify-content: space-between;
}
.filtro-precios {
  margin-right: 1%;
  height: 93%;
}

.MuiFormControl-root.MuiTextField-root {
  margin-right: 1%;
}

[class*="MuiPopover-paper"][class*="MuiMenu-paper"] {
  width: 16%;
  border-radius: 3%;
}

.modal-precio {
  margin-right: 1%; /* Espacio entre los campos de texto */
  border-radius: 3%;
  display: flex;
  align-items: center;
}

.separator {
  margin: 0 10px; /* Ajusta el espacio horizontal entre los elementos */
}

.boton-limpiar {
  margin-top: 0% !important;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Establece la altura al 100% del viewport */
}

/* CSS para pantallas pequeñas (vista móvil) */
@media screen and (max-width: 768px) {
  .componente-filtros {
    display: flex;
    flex-direction: column; /* Cambia la dirección de flexión a columna */
    margin-bottom: 1%;
    margin-top: 2%;
    margin-left: 2%;
    margin-right: 2%;
    /* position: fixed; */
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Establece la altura al 100% del viewport */
  }

  .filtros-row {
    display: flex;
    margin-bottom: 2%;
  }

  .filtros-row > * {
    flex: 1; /* Distribuye el espacio disponible de manera uniforme */
    height: 100%; /* Establece la altura al 100% */
  }

  .filtros-row2 {
    display: flex;
    justify-content: space-between;
  }

  /* Ajusta el espacio entre los filtros de precios */
  .filtro-precios {
    margin-right: 0.5%;
    height: 93%; /* Establece una altura diferente solo para el filtro de precios */
  }

  .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 33%;
  }

  [class*="MuiPopover-paper"][class*="MuiMenu-paper"] {
    width: 74% !important;
    border-radius: 3% !important;
    left: 5% !important;
    position: fixed !important;
  }

  .modal-precio {
    margin-right: 1%; /* Espacio entre los campos de texto */
    border-radius: 3%;
    display: flex;
    align-items: center;
    position: fixed;
  }

  .boton-limpiar {
    margin-top: 2% !important;
  }

  .separator {
    margin: 0 10px; /* Ajusta el espacio horizontal entre los elementos */
  }
}
