.adminLogo {
  width: 5% !important;
  margin-left: 3% !important;
}

@media screen and (max-width: 768px) {
  .adminLogo {
    width: 18% !important;
    margin-left: 40% !important;
  }
}
