.textos-titulo1 {
  text-align: center;
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  color: #571b3c;
}

.textos-titulo2 {
  text-align: center;
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  color: #571b3c;
}

.contenedor-kula-iframe {
  text-align: center;
  width: 93%;
  margin-left: 3.5%;
  height: 400px;
}
.kula-iframe-comercial {
  width: 100% !important;
  height: 100% !important;
  box-shadow: 0 0 70px #571b3c;
  border-radius: 20px;
  border: 4px solid #571b3c;
}

.google-maps-iframe {
  height: 300px;
  border: 0;
  flex: 1;
  margin-right: 4%; /* Ajusta el margen izquierdo del mapa */
}

.contenedor-descripcion-google {
  display: flex;
  margin-top: 3%;
  margin-bottom: 2%;
  align-items: center;
}

.texto-descripcion {
  flex: 1;
  padding-right: 2%; /* Ajusta el espacio entre el texto y el mapa */
  font-family: Georgia, serif;
  font-size: 20px;
  text-align: justify;
  margin-left: 4%;
}

.contenedor-redes-sociales {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2%;
  text-align: center;
}

.iconos-redes {
  display: flex;
  justify-content: center;
}

.iconos-redes a {
  margin: 0 10%; /* Espacio entre iconos del 10% */
}

.iconos-redes-sociales {
  font-size: 30px;
}

.texto-contacto {
  font-size: 25px;
  color: inherit;
  text-decoration: none;
  margin-top: 10px; /* Espacio entre iconos y texto de contacto */
}

/* CSS para pantallas pequeñas (vista móvil) */
@media screen and (max-width: 768px) {

  .textos-titulo1 {
    text-align: center;
    font-family: Georgia, serif;
  }
  
  .textos-titulo2 {
    text-align: center;
    font-family: Georgia, serif;
  }
  
  .kula-iframe {
    width: 100%;
    height: 300px;
    border: 0;

  }

  .contenedor-kula-iframe {
    text-align: center;
    width: 93%;
    margin-left: 2.5%;
    height: 300px;
  }
  .kula-iframe-comercial {
    width: 100% !important;
    height: 100% !important;
    box-shadow: 0 0 70px #571b3c;
    border-radius: 20px;
    border: 4px solid #571b3c;
  }
  
  .iconos-redes-sociales {
    font-size: 40px;
    margin-right: 10px;
  }
  
  .contenedor-descripcion-google {
    display: block;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-right: 4%;
    margin-left: 4%;
    align-items: center;
  }
  
  .texto-descripcion {
    font-family: Georgia, serif;
    font-size: 17px;
    text-align: justify;
    margin-left: 0%;
    padding-right: 0%; 
    margin-top: 10% !important;
  }

  .google-maps-iframe {
    height: 300px;
    border: 0;
    flex: 1;
    margin-right: 4%; /* Ajusta el margen izquierdo del mapa */
    width: 100%;
  }
  
  .contenedor-redes-sociales {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2%;
    text-align: center;
  }
  
  .iconos-redes {
    display: flex;
    justify-content: center;
  }
  
  .iconos-redes a {
    margin: 0 10%; /* Espacio entre iconos del 10% */
  }
  
  .iconos-redes-sociales {
    font-size: 30px;
  }
  
  .texto-contacto {
    font-size: 25px;
    color: inherit;
    text-decoration: none;
    margin-top: 10px; /* Espacio entre iconos y texto de contacto */
  }


  
}
